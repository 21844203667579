* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.main-page {
  background-color: #efefef;
  min-height: 100vh;
  padding: 30px;
}
.main-page .container {
  width: 50%;
  margin: auto;
  background-color: #fff;
  min-height: 600px;
  padding: 20px;
  border-radius: 20px;
  max-width: 1320px;
  font-family: "DM Sans", sans-serif;
}
.main-page .container .header-section {
  text-align: center;
  margin-bottom: 30px;
}
.main-page .container .header-section img {
  width: 80px;
}
.main-page .container .header-section h3 {
  font-size: 30px;
  color: #555;
  letter-spacing: -1.5px;
  padding: 5px;
  text-transform: capitalize;
}
.main-page .container .body-section {
  min-height: 200px;
  padding: 10px;
  border-radius: 10px;
}
.main-page .container .body-section-info h6 {
  font-size: 18px;
  letter-spacing: -1px;
  color: #555;
}
.main-page .container .body-section-form {
  margin-top: 20px;
}
.main-page .container .body-section-form-group {
  margin-bottom: 15px;
}
.main-page .container .body-section-form-group label {
  font-size: 15px;
  display: block;
  margin-bottom: 10px;
}
.main-page .container .body-section-form-group input {
  padding: 15px;
  width: 100%;
  outline: none;
  border: 1px solid #eee;
  font-size: 15px;
}
.main-page .container .body-section-form-group button.upload-btn {
  background-color: #4b9dd8;
  color: #fff;
  border: 2px solid #4b9dd8;
  border-radius: 3px;
  padding: 10px 15px;
  min-width: 150px;
  font-size: 14px;
}

@media (max-width: 992px) and (min-width: 577px) {
  .main-page {
    background-color: #ddd;
    min-height: 100vh;
    padding: 3rem 0.5rem;
  }
  .main-page .container {
    width: 85%;
    margin: auto;
    background-color: #fff;
    min-height: 600px;
    padding: 20px;
    border-radius: 20px;
  }
}
@media (max-width: 576px) {
  .main-page {
    background-color: #ddd;
    min-height: 100vh;
    padding: 3rem 0.5rem;
  }
  .main-page .container {
    width: 95%;
    margin: auto;
    background-color: #fff;
    min-height: 600px;
    padding: 20px;
    border-radius: 20px;
  }
}
@media (max-width: 375px) {
  .main-page {
    background-color: #ddd;
    min-height: 100vh;
    padding: 3rem 0.5rem;
  }
  .main-page .container {
    width: 95%;
    margin: auto;
    background-color: #fff;
    min-height: 500px;
    padding: 20px;
    border-radius: 20px;
  }
  .main-page .container .header-section {
    text-align: center;
    margin-bottom: 30px;
  }
  .main-page .container .header-section img {
    width: 60px;
  }
  .main-page .container .header-section h3 {
    font-size: 25px;
    color: #555;
    letter-spacing: -1px;
    padding: 5px;
    text-transform: capitalize;
  }
  .main-page .container .body-section {
    min-height: 150px;
    padding: 6px;
    border-radius: 10px;
  }
  .main-page .container .body-section-info h6 {
    font-size: 18px;
    letter-spacing: -1px;
    color: #555;
  }
  .main-page .container .body-section-form {
    margin-top: 20px;
  }
  .main-page .container .body-section-form-group {
    margin-bottom: 15px;
  }
  .main-page .container .body-section-form-group label {
    font-size: 13px;
    display: block;
    margin-bottom: 10px;
  }
  .main-page .container .body-section-form-group input {
    padding: 10px;
    width: 100%;
    outline: none;
    border: 1px solid #eee;
    font-size: 13px;
  }
  .main-page .container .body-section-form-group button.upload-btn {
    background-color: #4b9dd8;
    color: #fff;
    border: 2px solid #4b9dd8;
    border-radius: 3px;
    padding: 8px 12px;
    min-width: 130px;
    font-size: 12px;
  }
}/*# sourceMappingURL=main.css.map */